<template>
    <v-container fluid class="mb-2 white">
        <v-row>
            <v-col md="1" class="px-5">
                <v-row>
                    <v-text-field
                        label="Horaire"
                        type="time"
                        :value="virtualClassElement ? durationToInput(virtualClassElement.startingTime) : '00:00'"
                        readonly
                    ></v-text-field>
                </v-row>
                <v-row>
                    <v-text-field
                        label="Durée"
                        type="time"
                        :value="virtualClassElement ? durationToInput(virtualClassElement.duration) : '00:00'"
                        @change="updateValue('duration', $event)"
                        @focus="resetDebounce(false)"
                        @blur="resetDebounce(true)"
                        ref="duration"
                    ></v-text-field>
                </v-row>
            </v-col>
            <v-col md="6" class="px-5">
                <v-row>
                    <v-textarea
                        label="Objectifs"
                        @change="updateValue('objective', $event)"
                        @focus="resetDebounce(false)"
                        @blur="resetDebounce(true)"
                        :value="virtualClassElement ? virtualClassElement.objective : ''"
                        rows="1"
                        auto-grow
                        ref="objective"
                    ></v-textarea>
                </v-row>
                <v-row>
                    <v-textarea
                        label="Activités formateur"
                        @change="updateValue('activities', $event)"
                        @focus="resetDebounce(false)"
                        @blur="resetDebounce(true)"
                        :value="virtualClassElement ? virtualClassElement.activities : ''"
                        rows="1"
                        auto-grow
                        ref="activities"
                    ></v-textarea>
                </v-row>
            </v-col>
            <v-col class="px-5">
                <v-row>
                    <v-select
                        label="Contenus / Outils"
                        :items="['Vote', 'Vidéo', 'Outil quiz', 'Outil web', 'Brainstorming', 'Support formateur', 'Tableau blanc ou nuage de mots']"
                        @change="updateValue('content', $event)"
                        @focus="resetDebounce(false)"
                        @blur="resetDebounce(true)"
                        :value="virtualClassElement ? virtualClassElement.content : ''"
                        ref="content"
                    ></v-select>
                </v-row>
            </v-col>
            <v-col class="px-5">
                <v-row>
                    <v-select
                        label="Posture"
                        :items="['Passif', 'Actif', 'Actif/Passif']"
                        @change="updateValue('posture', $event)"
                        @focus="resetDebounce(false)"
                        @blur="resetDebounce(true)"
                        :value="virtualClassElement ? virtualClassElement.posture : ''"
                        ref="posture"
                    ></v-select>
                </v-row>
            </v-col>
            <v-col class="px-5">
                <v-row>
                    <v-select
                        label="Type d'échange"
                        :items="['Webcam et micro', 'Micro', 'Chat']"
                        @change="updateValue('support', $event)"
                        @focus="resetDebounce(false)"
                        @blur="resetDebounce(true)"
                        :value="virtualClassElement ? virtualClassElement.support : ''"
                        ref="support"
                    ></v-select>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import debounce from "debounce";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "VirtualClassElementLine",

    props: {
        virtualClassElementId: {
            type: String,
            require: false,
        },
        virtualClassId: {
            type: String,
            require: false,
        },
    },

    data() {
        return {
            launched: false,
        }
    },

    computed: {
        virtualClassElement: function() {
            return this.virtualClassElementId ? this.getVirtualClassElementById()(this.virtualClassElementId) : null;
        },
    },

    created() {
        this.debouncedCreate = debounce(() => {
            this.createElement();
        }, 1000);
    },

    methods: {
        ...mapActions("formations", ["modifyVirtualClassElement", "addVirtualClassElement"]),

        ...mapGetters("formations", ["getVirtualClassElementById"]),

        resetDebounce: function(set) {
            if(set) {
                // onBlur
                if(this.launched) {
                    this.debouncedCreate();
                }
            } else {
                // onFocus
                this.$nextTick(() => {
                    this.debouncedCreate.clear();
                });
            }
        },

        updateValue: async function(element, value) {
            if(!this.virtualClassElement) {
                this.launched = true;
                this.debouncedCreate();
                return;
            }

            if (element === "duration") {
                value = this.durationToNumber(value);
            }

            await this.modifyVirtualClassElement({
                virtualClassElementId: this.virtualClassElementId,
                [element]: value,
            });

            this.$emit("update");
        },

        createElement: async function() {
            const element = {};

            for(const item in this.$refs) {
                if (item === "duration") {
                    element[item] = this.durationToNumber(this.$refs[item].lazyValue);
                    this.$refs[item].lazyValue = "00:00";
                } else {
                    element[item] = this.$refs[item].lazyValue;
                    this.$refs[item].lazyValue = "";
                }

                this.$nextTick(() => {
                    this.$refs[item].blur();
                });
            }

            await this.addVirtualClassElement({ id: this.virtualClassId, element: element })
            
            this.launched = false;
            this.$emit("create");
        },

        durationToNumber: function(value) {
            const [hours, minutes] = value.split(":");
            return Number(hours) * 60 + Number(minutes);
        },

        durationToInput: function(value) {
            let hours = ~~(value / 60);
            let minutes = value % 60;

            if (hours < 10) {
                hours = "0" + hours;
            }

            if (minutes < 10) {
                minutes = "0" + minutes;
            }

            return hours + ":" + minutes;
        },
    },
};
</script>

<style scoped>
.container {
    border-bottom: black 2px solid !important;
}
</style>
