<template>
    <v-container fluid>
        <page-banner links="Ma formation/Classes virtuelles"></page-banner>
        <formation-header></formation-header>

        <v-row class="mt-10">
            <v-expansion-panels flat tile accordion>
                <v-expansion-panel v-for="(virtualClass, index) in virtualClasses" :key="virtualClass.id" class="mb-2">
                    <v-expansion-panel-header color="orange" class="subtitle-1 py-0">
                        <v-col md="1">
                            <v-icon>fas fa-sort-amount-down-alt</v-icon>
                        </v-col>
                        <v-col md="5" class="ml-n16">
                            <CustomInput
                                type="virtual"
                                :objectId="virtualClass.id"
                                valueName="title"
                                :background="false"
                            ></CustomInput>
                        </v-col>
                        <v-col offset-md="3" md="1">
                            {{ durations[index] | durationToHuman }}
                        </v-col>
                        <v-col md="1">
                            <download-button download-type="virtual" :object-id="virtualClass.id"></download-button>
                        </v-col>
                        <v-col md="1">
                            <v-btn
                                icon
                                class="hoverBtn"
                                @click.stop="
                                        dialogDelete = true;
                                        askForDelete = virtualClass.id;
                                    ">
                                <v-icon>fas fa-times</v-icon>
                            </v-btn>
                        </v-col>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="background">
                        <virtual-class :virtual-class="virtualClass"></virtual-class>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>

        <v-row class="mt-5">
            <v-btn icon @click="newClass"><v-icon color="black">fas fa-plus</v-icon></v-btn>
        </v-row>

        <v-dialog v-model="dialogNew" max-width="400">
            <v-card>
                <v-card-title>Nouvelle classe virtuelle </v-card-title>
                <v-card-text><v-text-field label="Nom de la classe virtuelle" v-model="newName"></v-text-field></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error darken-1"
                        text
                        @click="
                            dialogNew = false;
                            newName = '';
                        "
                    >
                        Annuler
                    </v-btn>

                    <v-btn
                        color="success darken-2"
                        text
                        @click="
                            dialogNew = false;
                            newClass(true);
                        "
                    >
                        Créer la classe
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400">
            <v-card>
                <v-card-title>Suppression </v-card-title>
                <v-card-text>Êtes vous sûr de vouloir supprimer cette classe virtuelle ?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="
                            dialogDelete = false;
                            askForDelete = null;
                        "
                    >
                        Annuler
                    </v-btn>

                    <v-btn
                        color="error darken-2"
                        text
                        @click="
                            dialogDelete = false;
                            deleteClass();
                        "
                    >
                        Supprimer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FormationHeader from "@/components/designer/FormationHeader";
import PageBanner from "@/components/PageBanner";
import VirtualClass from "@/components/designer/VirtualClass";
import DownloadButton from "@/components/designer/DownloadButton";
import CustomInput from "@/components/CustomInput";

export default {
    name: "VirtualClasses",

    components: {
        DownloadButton,
        VirtualClass,
        PageBanner,
        FormationHeader,
        CustomInput,
    },


    created() {
        this.getVirtualClasses();
        this.getVirtualClassElements();
    },

    data: () => ({
        dialogNew: false,

        dialogDelete: false,
        confirmDelete: "",
        askForDelete: "",

        newName: "",
    }),

    computed: {
        ...mapState("settings", ["user"]),
        ...mapState("formations", ["currentFormation", "virtualClasses", "virtualClassElements"]),

        classToDeleteTitle() {
            try {
                return this.virtualClasses.find((obj) => obj.id === this.askForDelete).title;
            } catch (e) {
                return "";
            }
        },

        virtualClassId() {
            return this.virtualClasses[this.currentPanel];
        },

        durations: function() {
            const durations = [];

            for(const index in this.virtualClasses) {
                const elements = this.virtualClassElements.filter(element => element.virtualClassId === this.virtualClasses[index].id)
                if(elements.length > 0) {
                    durations.push(elements[elements.length - 1].startingTime + elements[elements.length - 1].duration);
                }
            }

            return durations;
        }
    },

    methods: {
        ...mapActions("formations", [
            "getVirtualClassElements",
            "getVirtualClasses",
            "addVirtualClass",
            "removeVirtualClass",
            "addVirtualClassElement",
        ]),

        deleteClass() {
            this.removeVirtualClass(this.askForDelete);
        },

        async newClass(toCreate) {
            if (toCreate === true) {
                await this.addVirtualClass(this.newName);
                await this.getVirtualClassElements();
                this.newName = "";
            } else {
                this.dialogNew = true;
            }
        },
    },
};
</script>

<style scoped>
.v-card__text > .row:first-child {
    border-bottom: 1px grey solid;
}

.v-expansion-panel >>> .v-expansion-panel-content__wrap {
    padding-left: 12px;
    padding-right: 12px;
}
</style>
