<template>
    <v-row class="subtitle-1">
        <v-col class="px-0">
            <v-row class="text-center my-1 orange lighten-1 mx-0">
                <v-col md="1">Horaires</v-col>
                <v-col md="6">Objectifs et activités de l'étape</v-col>
                <v-col>Contenus / Outils</v-col>
                <v-col>Posture participant</v-col>
                <v-col>Echanges</v-col>
            </v-row>

            <virtual-class-element-line
                v-for="element in elements"
                :key="element.id"
                :virtual-class-element-id="element.id"
                @update="getVirtualClassElements"
            ></virtual-class-element-line>

            <virtual-class-element-line
                @create="getVirtualClassElements"
                :virtual-class-id="virtualClass.id"
            ></virtual-class-element-line>

        </v-col>
    </v-row>
</template>

<script>
import VirtualClassElementLine from "@/components/designer/VirtualClassElementLine";
import { mapActions, mapState } from "vuex";

export default {
    name: "VirtualClass",

    components: { VirtualClassElementLine },

    props: {
        virtualClass: {
            type: Object,
            require: true,
        }
    },

    computed: {
        ...mapState("formations", ["virtualClassElements"]),

        elements: function() {
            return this.virtualClassElements.filter(element => element.virtualClassId === this.virtualClass.id)
        }
    },

    methods: {
        ...mapActions("formations", [
            "addVirtualClassElement",
            "getVirtualClassElements",
        ]),
    },

};
</script>

<style scoped>

</style>